/* HC Icon Font */
@font-face {
    font-family: "hc-iconfont";
    src: url(https://review-service.holidaycheck.com/themes/custom/hcpw_base/fonts/hc-iconfont/hc-iconfont.ttf) format("truetype"),
        url(https://review-service.holidaycheck.com/themes/custom/hcpw_base/fonts/hc-iconfont/hc-iconfont.woff) format("woff"),
        url(https://review-service.holidaycheck.com/themes/custom/hcpw_base/fonts/hc-iconfont/hc-iconfont.svg#hc-iconfont) format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    font-weight: 300;
    font-style: normal;
    src: url('https://review-service.holidaycheck.com/themes/custom/hcpw_base/fonts/open-sans/OpenSans-Light.ttf') format("truetype");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    src: url('https://review-service.holidaycheck.com/themes/custom/hcpw_base/fonts/open-sans/OpenSans-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    src: url('https://review-service.holidaycheck.com/themes/custom/hcpw_base/fonts/open-sans/OpenSans-SemiBold.ttf') format("truetype");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 700;
    font-style: normal;
    src: url('https://review-service.holidaycheck.com/themes/custom/hcpw_base/fonts/open-sans/OpenSans-Bold.ttf') format("truetype");
}


.icon {
    font-family: "hc-iconfont" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
}

.icon-sun::before {
    content: "\E604";
}

.icon-star::before {
    content: "\E605";
}

.icon-like::before {
    content: "\E607";
}

.icon-dislike::before {
    content: "\E62D";
}

.icon-right-arrow-line::before {
    content: "\E60A";
}

.icon-left-arrow-line::before {
    content: "\E60B";
}

.icon-location::before {
    content: "\E616";
}

.icon-bed::before {
    content: "\E618";
}

.icon-profile-edit::before {
    content: "\E625";
}

.icon-smiley-very-sad::before {
    content: "\E90A";
}

.icon-smiley-sad::before {
    content: "\E90B";
}

.icon-smiley-fine::before {
    content: "\E90C";
}

.icon-smiley-good::before {
    content: "\E90D";
}

.icon-smiley-very-good::before {
    content: "\E90E";
}

.icon-others::before {
    content: "\E922";
}

.icon-service::before {
    content: "\E910";
}

.icon-restaurant::before {
    content: "\E61A";
}

.icon-beach::before {
    content: "\E61C";
}

.icon-recreation::before {
    content: "\E61E";
}

.icon-travel-city::before {
    content: "\E902";
}

.icon-travel-business::before {
    content: "\E903";
}

.icon-travel-winter::before {
    content: "\E904";
}

.icon-travel-wellness::before {
    content: "\E905";
}

.icon-travel-culture::before {
    content: "\E906";
}

.icon-review-alone::before {
    content: "\E648";
}

.icon-review-couple::before {
    content: "\E649";
}

.icon-review-family::before {
    content: "\E64A";
}

.icon-review-friends::before {
    content: "\E64B";
}

.icon-down-arrow-line::before {
    content: "\E62C";
}

.icon-up-arrow-line::before {
    content: "\E62B";
}

.sun-full .icon-sun {
    color: #fad73c;
}

.sun-empty .icon-sun {
    color: #dfe2e6;
}